<template>
  <v-container>
    <v-row>
      <h4 class="System_admin_text_Product ml-2">
        CALCULATING ADJUSTED CASH FLOW (Seller's Discretionary Income / SDE)
      </h4>
    </v-row>

    <v-row id="Deal">
      <v-col cols="12" sm="3">
        <p>
          <span
            ><b>CLIENT: </b>
            <span
              ><b>{{ this.userName }}</b></span
            ></span
          >
        </p>
      </v-col>
    </v-row>

    <v-row id="Button Group">
      <v-col cols="9">
        <div class="text-right">
          <b-button-group>
            <b-button class="save-button" @click="saveFormData">Save</b-button>
            <b-button class="wizard-button-group" @click="openCBRDIY">
              Worksheet #2: CBR
            </b-button>
          </b-button-group>
        </div>
      </v-col>
    </v-row>

    <v-row id="Company/Year">
      <v-col cols="5">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>Company Name:</b-input-group-text>
          </template>
          <b-form-input
            v-model="wsData.company_name"
            style="background-color: yellow"
          >
          </b-form-input>
        </b-input-group>
      </v-col>
      <v-col cols="5">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>Enter Year:</b-input-group-text>
          </template>
          <b-form-select
            v-model="wsData.work_year"
            style="background-color: yellow"
            :options="list_years"
            @change="getYearlyData"
          >
          </b-form-select>
        </b-input-group>
      </v-col>
    </v-row>

    <v-row id="Cashflow Table">
      <div class="col-md-12" style="margin-top: 20px">
        <v-simple-table style="border-radius: 7px; border: 1px solid #c4c4c4">
          <template>
            <thead style="color: azure">
              <tr>
                <th class="header-black-and-white">ITEM</th>
                <th class="header-black-and-white">WHERE FOUND</th>
                <th class="header-black-and-white">COMMENTS</th>
                <th class="header-black-and-white" style="text-align: right">
                  NUMBERS
                </th>
              </tr>
            </thead>
            <tbody>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Start with Net Income (also called Net Profits)
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  On the Income Statement (also called P&L) – Also found on TAX
                  returns.
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Make sure tax returns and financial statements agree. If there
                  is a difference, prepare an explanation
                </td>
                <td style="background-color: yellow">
                  <money v-model="wsData.net_income"></money>
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Add Owners Salary + Payroll Taxes
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Income Statement, Tax Return, or Payroll report
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  If there is more than one owner, you will need to document. It
                  also makes sense to be able to discuss what a “replacement”
                  salary would be for someone else to do the same job.
                </td>
                <td style="background-color: yellow">
                  <money v-model="wsData.salary_taxes"></money>
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Add Amortization & Depreciation
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Income Statement or Tax Return
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Ensure the numbers agree.
                </td>
                <td style="background-color: yellow">
                  <money v-model="wsData.amortization"></money>
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Add Interest Expense
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Income Statement or Bank Statements
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Include interest from all business loans, but document loans
                  and amounts.
                </td>
                <td style="background-color: yellow">
                  <money v-model="wsData.int_exp"></money>
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Add Non-Recurring Expenses
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Income Statement
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Any one-time expenses which the new owner should not expect.
                  Document and describe.
                </td>
                <td style="background-color: yellow">
                  <money v-model="wsData.non_recur_exp"></money>
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">Add Owner Perks</td>
                <td style="border-right: 1px solid #c4c4c4">
                  Income Statement
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Use the attached sheet (next tab - "Owners Perks") to DETAIL.
                  Total is pulled from that tab.
                </td>
                <td style="background-color: greenyellow">
                  {{ usCurrency.format(this.perks) }}
                  <span style="float: right"
                    ><i class="fa fa-edit" @click="ownerPerks()"></i
                  ></span>
                </td>
              </tr>
              <tr class="gray">
                <td style="border-right: 1px solid #c4c4c4">
                  Equals Owner’s Cash Flow
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Spreadsheet calculates this total from the inputs above.
                </td>
                <td style="border-right: 1px solid #c4c4c4">
                  Also called Seller’s Discretionary Income (or SDE)
                </td>
                <td style="background-color: greenyellow">
                  {{ usCurrency.format(this.cashFlow) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-row>

    <v-dialog v-model="dialogPerks" max-width="1200" persistent>
      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click="closeEdit">
              <v-img src="../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>Owner Perks</h6>
              </div>
              <div class="customer-form">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-container>
                    <v-row class="px-5">
                      <!-- PLACE COLUMNS HERE -->
                      <p></p>
                      <v-row id="Cashflow Table">
                        <div class="col-md-12" style="margin-top: 20px">
                          <v-simple-table
                            style="
                              border-radius: 7px;
                              border: 1px solid #c4c4c4;
                            "
                          >
                            <template>
                              <thead style="color: azure">
                                <tr>
                                  <th class="header-black-and-white">ITEM</th>
                                  <th class="header-black-and-white">
                                    WHERE FOUND
                                  </th>
                                  <th class="header-black-and-white">
                                    COMMENTS
                                  </th>
                                  <th
                                    class="header-black-and-white"
                                    style="text-align: right"
                                  >
                                    NUMBERS
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Car Payment-Owner
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    If the company provides a car that the
                                    company paid cash for (in one year) but does
                                    not have an ongoing monthly payment,
                                    amortize the expense to approximate an
                                    annual number.
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.car_pay_owner"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Car Payment –Spouse or Family Member
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Personal, not business use; see comments
                                    above.
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.car_pay_Spouse"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Car Insurance – Owner
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    (Note: if you are filling out this Word
                                    Document for Support, you can use this
                                    column for more explanation. The more
                                    support provided the better!)
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.car_ins_owner"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Car Insurance – Spouse or Family Member
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.car_ins_spouse"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Health Insurance – Owner
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.health_ins_owner"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Health Insurance – Spouse or Family
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.health_ins_spouse"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Life Insurance – Owner
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.life_ins_owner"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Life Insurance – Spouse or Family
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.life_ins_spouse"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    401K / IRA / Retirement – Owner or Spouse
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: yellow">
                                    <money v-model="wsData.ira"></money>
                                  </td>
                                </tr>

                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Charitable Contributions
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Personal, ones that will not be required to
                                    maintain for normal business purposes.
                                  </td>
                                  <td style="background-color: yellow">
                                    <money
                                      v-model="wsData.charitable_contrib"
                                    ></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Meals & Entertainment
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Owner or family for personal purposes – not
                                    business related
                                  </td>
                                  <td style="background-color: yellow">
                                    <money v-model="wsData.meals"></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Country Club Dues / Memberships
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Owner or family for personal purposes – not
                                    business related
                                  </td>
                                  <td style="background-color: yellow">
                                    <money v-model="wsData.memberships"></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Travel
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Owner or family for personal purposes – not
                                    business related
                                  </td>
                                  <td style="background-color: yellow">
                                    <money v-model="wsData.travel"></money>
                                  </td>
                                </tr>

                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Family Compensation
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Income Statement, Invoices, or General
                                    Ledger entries
                                  </td>
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Salary + Taxes – if family member does not
                                    work or is overcompensated for role. Prorate
                                    compared to replacement cost if
                                    overcompensated.)
                                  </td>
                                  <td style="background-color: yellow">
                                    <money v-model="wsData.family_comp"></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Other
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: yellow">
                                    <money v-model="wsData.other"></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Other
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: yellow">
                                    <money v-model="wsData.other_a"></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td style="border-right: 1px solid #c4c4c4">
                                    Other
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: yellow">
                                    <money v-model="wsData.other_b"></money>
                                  </td>
                                </tr>
                                <tr class="gray">
                                  <td
                                    style="
                                      font-weight: 800;
                                      border-right: 1px solid #c4c4c4;
                                    "
                                  >
                                    Total
                                  </td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td
                                    style="border-right: 1px solid #c4c4c4"
                                  ></td>
                                  <td style="background-color: greenyellow">
                                    {{ usCurrency.format(this.perks) }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div>
                      </v-row>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="6000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CommonServices from "../service/CommonServices";
import { mask } from "vue-the-mask";
import { Money } from "v-money";

export default {
  components: { Money },
  directives: {
    mask,
  },

  created() {
    //this.checkPermissions();
    this.logActivity("Opened", localStorage.getItem("DealID"));

    this.usCurrency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    CommonServices.getItem(this.componentURL)
      .then((res) => {
        console.log("Response: ", res);
        if (res.count == 0) {
          this.getYearlyData();
          this.wsData.net_income = 0;
        } else {
          console.log("Current Year: ", this.wsData.work_year);
          console.log("Current CBR ID: ", res.results[0].id);
          this.getData(res.results[0].id);
          console.log("Current Year: ", this.wsData.work_year);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },

  data() {
    return {
      componentURL: `CashflowDIY/?work_year=${
        new Date().getFullYear() - 1
      }&user_id=${this.$route.params.id}`,
      componentURL_base: `CashflowDIY`,
      canpost: localStorage.getItem("canpost"),
      candelete: localStorage.getItem("candelete"),
      canput: localStorage.getItem("canput"),
      isAdmin: localStorage.getItem("Admin"),
      isBroker: localStorage.getItem('role')==2,
      isSeller: localStorage.getItem('role')==3,  
      isBuyer: localStorage.getItem('role')==4,
      isDIY: localStorage.getItem('role')==7,
      role: localStorage.getItem('role'),
      valid: false,
      dialog: false,
      dialogDelete: false,
      dialogPerks: false,
      dialogSelectUser: false,
      deletedId: null,
      snackbar: false,
      snackColor: "",
      snackText: "",
      error: false,
      loading: false,
      editedIndex: -1,
      searchText: "",
      file: "",
      profileImageUrl: "",
      currentDeal: localStorage.getItem("Deal"),
      userName:
        localStorage.getItem("first_name") +
        " " +
        localStorage.getItem("last_name"),
      show: false,
      variants: [
        "primary",
        "secondary",
        "success",
        "warning",
        "danger",
        "info",
        "light",
        "dark",
      ],
      headerBgVariant: "success",
      headerTextVariant: "light",
      bodyBgVariant: "light",
      bodyTextVariant: "dark",
      footerBgVariant: "success",
      footerTextVariant: "dark",
      errorTitle: "",
      errorText: "",
      dlgError: false,
      usCurrency: null,
      currentUser: localStorage.getItem("userid"),
      backDoor: 0,

      workYear: new Date().getFullYear() - 1,
      companyName: "",

      items: [],
      list_years: [
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025",
        "2026",
        "2027",
        "2028",
      ],

      wsData: {
        id: "",
        user_id: this.$route.params.id,
        company_name: localStorage.getItem("Deal"),
        work_year: new Date().getFullYear() - 1,

        net_income: 0,
        salary_taxes: 0,
        amortization: 0,
        int_exp: 0,
        non_recur_exp: 0,
        car_pay_owner: 0,
        car_pay_Spouse: 0,
        car_ins_owner: 0,
        car_ins_spouse: 0,
        health_ins_owner: 0,
        health_ins_spouse: 0,
        life_ins_owner: 0,
        life_ins_spouse: 0,
        ira: 0,
        charitable_contrib: 0,
        meals: 0,
        memberships: 0,
        travel: 0,
        family_comp: 0,
        other: 0,
        other_a: 0,
        other_b: 0,
        other_exp: 0,
        other_exp_a: 0,
        other_exp_b: 0,
      },

      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        suffix: " ",
        precision: 2,
        masked: false,
      },

      userActivityItem: {
        deal_id: localStorage.getItem("userid"),
        logdate: this.$moment(String(Date())).format("YYYY-MM-DDThh:mm"),
        page: "CashFlowCalculatorDIY",
        action: "",
        user_id: "",
        json: "",
      },

      mask: {
        maxDollar: "############",
        zipCode: "#####",
        ssn: "###-##-####",
        value: "######",
        phone: "(###) ###-####",
      },
    };
  },

  computed: {
    cashFlow() {
      return (
        this.wsData.net_income +
        this.wsData.salary_taxes +
        this.wsData.amortization +
        this.wsData.int_exp +
        this.wsData.non_recur_exp +
        this.perks
      );
    },

    perks() {
      return (
        this.wsData.car_pay_owner +
        this.wsData.car_pay_Spouse +
        this.wsData.car_ins_owner +
        this.wsData.car_ins_spouse +
        this.wsData.health_ins_owner +
        this.wsData.health_ins_spouse +
        this.wsData.life_ins_owner +
        this.wsData.life_ins_spouse +
        this.wsData.ira +
        this.wsData.charitable_contrib +
        this.wsData.meals +
        this.wsData.memberships +
        this.wsData.travel +
        this.wsData.family_comp +
        this.wsData.other +
        this.wsData.other_a +
        this.wsData.other_b
      );
    },
  },

  methods: {
    checkPermissions() {
      if (this.isAdmin == "false") this.$router.push("/");
    },

    openCBRDIY() {
      this.$router.push(`/CBRWorksheetDIY/${this.currentUser}`);
    },

    logActivity(activity, dealid, json) {
      this.userActivityItem.action = activity;
      this.userActivityItem.logdate = this.$moment(String(Date())).format(
        "YYYY-MM-DDThh:mm"
      );
      this.userActivityItem.user_id = dealid;
      this.userActivityItem.json = json;
      CommonServices.postData("UserActivityLog", this.userActivityItem)
        .then((response) => {
          console.log("UserActivityLog Response: ", response.id);
        })
        .catch((error) => {
          console.log("Post Activity Error: ", error);
        });
    },

    ownerPerks() {
      this.dialogPerks = true;
    },

    getYearlyData() {
      this.workYear = this.wsData.work_year;
      this.companyName = this.wsData.company_name;
      CommonServices.getList(
        `Cashflow/?work_year=${this.workYear}&user_id=${this.$route.params.id}`
      )
        .then((res) => {
          console.log("Current Year: ", this.wsData.work_year);
          console.log("Res: ", res);
          if (res.count == 0) {
            this.wsData = { ...this.wsData, ...res };
            this.wsData.company_name = localStorage.getItem("Deal");
            this.wsData.user_id = this.$route.params.id;
            this.wsData.work_year = this.workYear;
            //this.dialogPerks = true;
            //this.dialogPerks=false;
          } else {
            this.getData(res.results[0].id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    closeEdit() {
      this.dialogPerks = false;
    },

    resetItems() {
      console.log("Resetting items...");
      this.wsData = {
        id: "",
        user_id: this.$route.params.id,
        company_name: localStorage.getItem("Deal"),
        net_income: null,
        salary_taxes: null,
        amortization: null,
        int_exp: null,
        non_recur_exp: null,
      };
    },

    saveFormData() {
      console.log("Last updated: ", this.wsData.last_updated);
      console.log("DealID: ", this.wsData.user_id);
      if (this.wsData.id) {
        CommonServices.updateData(this.componentURL_base, this.wsData)
          .then((response) => {
            const index = this.items.findIndex(
              (item) => item.id === response.id
            );
            this.$set(this.items, index, response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully updated";
            this.logActivity(
              "Saved Form Data",
              this.wsData.user_id,
              JSON.stringify(this.wsData)
            );
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.response.data).forEach((key) => {
                this.snackText = error.response.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      } else {
        CommonServices.addRecord(this.componentURL_base, this.wsData)
          .then((response) => {
            this.items.push(response);
            this.snackbar = true;
            this.snackColor = "success";
            this.snackText = "Record successfully added";
          })
          .catch((error) => {
            this.snackbar = true;
            this.snackColor = "error";
            try {
              Object.keys(error.data).forEach((key) => {
                this.snackText = error.data[key][0];
              });
            } catch (e) {
              this.snackText = "Something went wrong";
            }
          });
      }
    },

    getData(ID) {
      console.log("Cashflow Detail Response: ", "test");
      CommonServices.Detail(ID, this.componentURL_base)
        .then((res) => {
          console.log("Cashflow Detail Response: ", res);
          this.wsData = res;
          this.$forceUpdate();

          //this.dialogPerks=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    openTasks() {
      this.$router.push(`/Tasks/${this.$route.params.id}`);
    },

    openOffers() {
      this.$router.push(`/DealOffers/${this.$route.params.id}`);
    },

    openUploads() {
      //First write any missing Deal Folders to the upload table
      CommonServices.putData("Functions", { id: 3 })
        .then((response) => {
          console.log("Put Function Response: ", response.id);
          setTimeout(() => {
            this.$router.push(`/Uploads/${this.$route.params.id}`);
          }, 500);
        })
        .catch((error) => {
          console.log("Put Function Error: ", error);
        });
    },

    goBack() {
      return this.$router.go(-1);
    },
  },
};
</script>

<style type="text/css" src="../css/styles.css"></style>
